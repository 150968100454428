import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getByCedula, hasAccess } from '../../http/monthly-pay-services';
import GCPrimaryButton from '../../primitives/buttons/GCPrimaryButton';

import Moment from 'moment';
import GCInput from '../../primitives/inputs/GCInput.primitive';
import CancelSVG from '../assets/cancel.svg';
import CheckCircleSVG from '../assets/check_circle.svg';
import { getTrainingsAssitantsForTheDay } from '../../http/entreno-services';
import GCDropdown from '../../primitives/dropdowns/GCDropdown.primitive';
import { reservaCreateMultipleTrainers } from '../../http/reserva-services';
import GCSpinner from '../../primitives/spinner/GCSpinner';
import { IEntreno } from '../Trainings/Entreno.interface';

export const PAGINA_INICIAL = '' + 1;

function RegisterUserAccess() {
    const [cedula, setCedula] = useState<string>('');
    const [tieneAcceso, setTieneAcceso] = useState<string>('');
    const [trainingsOfTheDay, setTrainingsOfTheDay] = useState<Array<any>>([]);
    const [selectedTraining, setSelectedTraining] = useState<any>({ key: '0', id: '0', description: 'selecciona el entreno por favor' });
    const [activeMonthlyPay, setActiveMonthlyPay] = useState<any>({
        fecha_inicio: '',
        fecha_fin: '',
        fecha_inicio_str: '',
        fecha_fin_str: '',
        mensualidad: { titulo: '' },
        cliente: { nombre: '' },
    })
    const trainingPoint = useAppSelector((state) => state?.trainingPoint?.trainingPoint)
    const [disabledState, setDisabledState] = useState<boolean>(true);
    const [loadedHasAccess, setLoadedHasAccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [userPoints, setUserPoints] = useState<number>(0);
    const [requiredPoints, setRequiredPoints] = useState<number>(0);


    const handleSubmit = async () => {
        setLoading(true);
        const monthlyPays = await getByCedula(cedula);
        setLoadedHasAccess(false);
        try {

            const hasAccessResponse = await hasAccess(cedula, selectedTraining.id);
            setLoadedHasAccess(true);
            setTieneAcceso(hasAccessResponse.hasAccess);
            setUserPoints(hasAccessResponse.userPoints);
            setRequiredPoints(hasAccessResponse.requiredPoints);
            setLoading(false);

            let tmpActiveMonthlyPay = {
                fecha_inicio: '',
                fecha_fin: '',
                fecha_inicio_str: '',
                fecha_fin_str: '',
                mensualidad: { titulo: '' }
            }
            if (monthlyPays.data.length > 0) {
                tmpActiveMonthlyPay = monthlyPays.data.pop();
                // tmpActiveMonthlyPay.fecha_inicio_date = new Date(tmpActiveMonthlyPay.fecha_inicio);
                tmpActiveMonthlyPay.fecha_inicio_str = Moment(new Date(tmpActiveMonthlyPay.fecha_inicio)).format('DD-MM-YYYY');
                // tmpActiveMonthlyPay.fecha_fin_date = new Date(tmpActiveMonthlyPay.fecha_fin);
                tmpActiveMonthlyPay.fecha_fin_str = Moment(new Date(tmpActiveMonthlyPay.fecha_fin)).format('DD-MM-YYYY');

                setActiveMonthlyPay(tmpActiveMonthlyPay);
                if (hasAccessResponse.hasAccess) {
                    createReservation();
                    setLoading(false);

                } else {
                    setLoading(false);
                }
            } else {
                setLoading(false);
                setLoadedHasAccess(true);
                setTieneAcceso('');
            }
        } catch (e) {
            setLoading(false);
            setLoadedHasAccess(true);
            //console.log(e.response.status==404){
        }

    };

    const createReservation = async () => {
        let formattedTraining = [{
            entreno_id: selectedTraining.id,
            numeroInvitados: "0",
        }];

        await reservaCreateMultipleTrainers(cedula, formattedTraining);
    }

    const getFechaString = (fecha: Date) => {
        const tzoffset = (new Date()).getTimezoneOffset() * 60000;
        const fechaPrevia = new Date(fecha);
        const fechaWOffset: Date = new Date(fechaPrevia.getTime() - tzoffset);
        return fechaWOffset.toISOString().slice(0, -1);
    }

    const getTrainingsOfTheDay = async () => {
        const trainings = await getTrainingsAssitantsForTheDay(
            '',
            PAGINA_INICIAL,
            trainingPoint.id,
            getFechaString(new Date())
        )

        trainings.map((training: any) => {
            training.description = getTrainingDescription(training);
            training.key = training.id;
            return training;
        })
        setTrainingsOfTheDay(trainings)
    }
    const getTrainingDescription = (training: IEntreno) => {

        return (
            "Clase de " + training.clase.descripcion +
            " a las " +
            formatDate(training)
        );

    }

    const formatDate = (training: IEntreno) => {
        let fecha = new Date(training.hora);
        return fecha.getHours() + ":" + formatTwoDigitsHour(fecha)
    }
    //
    /*
    consultarDatosActividad(filtro: string, sateliteId ?: number, hora ?: string, activityId ?: string): Observable < any > {
        return this.service.getDatos('',
            PAGINA_INICIAL,
            sateliteId,
            hora,
            activityId
        );
    }
    */

    useEffect(() => {
        getTrainingsOfTheDay();
    }, [1]);

    const formatTwoDigitsHour = (dateToFormat: Date) => {
        let formatted = (dateToFormat.getMinutes() < 10 ? '0' : '') + dateToFormat.getMinutes();
        return formatted;
    }

    const onSelectedOptionChangeFn = (selectedTrainingVal: any) => {
        setSelectedTraining(selectedTrainingVal);
        updateCTAState(cedula, selectedTrainingVal);
    }

    const updateCTAState = (cedulaVal: string, selectedTraining: any) => {
        if (cedulaVal && cedulaVal.length > 0 && selectedTraining && selectedTraining?.clase?.id > 0) {
            setDisabledState(false);
        } else {
            setDisabledState(true);
        }
    }

    const showErrorMessage = () => {
        if (
            // activeMonthlyPay?.mensualidad.titulo 
            loadedHasAccess
            && !tieneAcceso) {
            return true;
        }
        return false;
    }

    const getLastMonthlyPlanMessage = () => {
        if (activeMonthlyPay?.mensualidad?.titulo) {
            return (


                <span>Último plan:&nbsp;
                    <b>
                        {activeMonthlyPay?.mensualidad?.titulo}&nbsp;</b>
                    del <b>{activeMonthlyPay?.fecha_inicio_str}</b> al <b>{activeMonthlyPay?.fecha_fin_str}</b>
                </span>)
        }

        return (
            <span>Último plan:&nbsp;
                <b>
                    No tiene ningún plan registrado</b>
            </span>
        )
    }

    const getUserMessage = () => {
        if (activeMonthlyPay?.cliente?.nombre) {
            return (
                <h3><b>{activeMonthlyPay?.cliente?.nombre}&nbsp;</b>no tiene acceso a este entreno</h3>
            )
        }

        return (
            <h3>El usuario no tiene acceso a este entreno</h3>
        )


    }

    const getClassStyle = (training: IEntreno) => {
        if (selectedTraining.id == training.id) {
            return ' bg-light-gray ';
        }

        return ' border-light-gray bg-white rounded-md ';
    }
    return (
        <div className='flex w-full justify-center bg-min-gray h-full'>
            <div className='flex flex-col gap-4 py-4 w-1/2 justify-left items-center'>
                {showErrorMessage() ?
                    <div className="flex flex-col py-4 px-8 justify-center w-full rounded-lg bg-danger">
                        <div className='flex gap-8 items-center'>
                            <div className='flex'>
                                <img className="h-10 mr-2" src={CancelSVG} alt="CancelSVGIcon" />
                            </div>
                            <div className='flex flex-col'>
                                {getUserMessage()}
                                <div className="flex flex-col">
                                    <div>{getLastMonthlyPlanMessage()}</div>
                                    <div>
                                        Requiere <b> {requiredPoints} Puntos Fit</b> y el usuario tiene <b>{userPoints} Puntos Fit</b>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> : ''
                }

                {activeMonthlyPay?.mensualidad.titulo && tieneAcceso ?
                    <div className="flex flex-col py-4 px-8 justify-center w-full rounded-lg bg-success">
                        <div className='flex gap-8 items-center'>
                            <div className='flex'>
                                <img className="h-10 mr-2" src={CheckCircleSVG} alt="CancelSVGIcon" />
                            </div>
                            <div className='flex flex-col'>
                                <h3>Registro de entrada exitoso para <b>{activeMonthlyPay?.cliente?.nombre}&nbsp;</b></h3>
                                <div className="flex flex-col">
                                    <span>Plan:&nbsp;
                                        <b>
                                            {activeMonthlyPay?.mensualidad?.titulo}&nbsp;</b>
                                        del <b>{activeMonthlyPay?.fecha_inicio_str}</b> al <b>{activeMonthlyPay?.fecha_fin_str}</b>
                                    </span>
                                </div>
                                <div>
                                    Se usaron <b> {requiredPoints} Puntos Fit</b> y quedaron <b>{userPoints - requiredPoints} Puntos Fit</b>
                                </div>
                            </div>

                        </div>
                    </div> : ''

                }
                <h1 className=''>Registrar entrada de usuario</h1>
                <div className="flex flex-col items-center gap-2 w-2/5 justify-center w-full">
                    <div className='flex w-full flex-col'>
                        <label className="font-bold" htmlFor="monthlyfee-cedula">Clases del día</label>
                        <div className='flex'>
                            {
                                trainingsOfTheDay?.map((training, index) => (
                                    <div
                                        className={'py-4 mr-2  max-w-xs px-2 border-solid border rounded-md' + getClassStyle(training)
                                        }

                                        onClick={() => { onSelectedOptionChangeFn(training) }}>

                                        {selectedTraining.id == training.id ? (<span className="">
                                            <b>
                                                {training?.clase?.descripcion} {formatDate(training)}
                                            </b>
                                        </span>) :
                                            (
                                                <span className="text-black">
                                                    <b>
                                                        {training?.clase?.descripcion} {formatDate(training)}
                                                    </b>
                                                </span>
                                            )
                                        }
                                        {training?.reservations?.map((reservation: any) => (
                                            <div>
                                                <small>
                                                    {reservation?.cliente_id?.nombre}
                                                </small>
                                            </div>
                                        )
                                        )}


                                    </div>

                                ))}
                        </div>
                    </div>

                    <div className="flex flex-col w-full">
                        <label className="font-bold" htmlFor="monthlyfee-cedula">Cédula</label>
                        <GCInput id="monthly-cedula" type="text" name="monthly-cedula" previousValue="" placeholder="" onChange={(newValue) => {
                            setCedula(newValue);
                            updateCTAState(newValue, selectedTraining);
                        }} />
                    </div>
                    <div className='flex w-full'>
                        <GCPrimaryButton disabled={disabledState} text={'Guardar'} id={'next'} onClick={handleSubmit} />
                    </div>

                    {loading ? (
                        <div className="flex mt-2 justify-center w-full">
                            <GCSpinner></GCSpinner>
                        </div>
                    ) : ''}
                </div>
            </div>
        </div >
    );
}

export default RegisterUserAccess;